import { graphql } from 'gatsby';
import React, { useState } from 'react';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Helmet } from 'react-helmet';
import REDIRECTS_FILE_CONTENT from '../../../_redirects';
import { OverridablePlan } from '../../components/modules/PlansModule';
import PageSEO from '../../components/PageSEO';
import { Module } from '../../components/ui/Section';
import {
  CHECKOUT_URL,
  DEFAULT_SCHEDULE_ONCE_CALENDAR,
  FILE_DOWNLOAD_URLS_BY_PARAM_VALUE,
} from '../../constants';
import { uniq } from '../../utils/nodash';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx, urlJoin } from '../../utils/utils';
import * as styles from './internal--flows-nj7c62heryy6najv.module.scss';

export const query = graphql`
  query {
    sanityGetFileSettings {
      downloadableFiles {
        fileParameterSlug {
          current
        }
        fileUrl
      }
    }
    allSanityLpViralPlansPage {
      totalCount
      nodes {
        title
        slug {
          current
        }
        sections {
          content {
            ...Modules
          }
        }
      }
    }
    allSanityLpVslPage {
      nodes {
        internalName
        slug {
          current
        }
        sections {
          content {
            ...Modules
          }
        }
      }
    }
    allSanityHpMarketingBrochure {
      nodes {
        title
        slug {
          current
        }
        ctaSection {
          withTypeform
          typeformId
          url
        }
      }
    }
    allSanityGuideWorkshop {
      nodes {
        title
        slug {
          current
        }
        ctaSection {
          formType
          url
          typeformId
        }
      }
    }
    allSanityGtgvBookLandingPage {
      nodes {
        title
        slug {
          current
        }
        hero {
          destinationUrlOnSubmit
          onSubmitKeapTagIds
        }
      }
    }
    allSanityWelcomePage {
      nodes {
        title
        slug {
          current
        }
      }
    }
    allSanityHpConsultingPackage {
      nodes {
        title
        slug {
          current
        }
      }
    }
    allSanityLpLeadGen {
      nodes {
        title
        slug {
          current
        }
      }
    }
    sanityWelcomePageCommonFields {
      supportSection {
        supportTeamMembers {
          email
        }
      }
    }
  }
`;

interface BasicPage {
  title: string;
  slug: {
    current: string;
  };
}

export interface QueryData {
  data: {
    allSanityGtgvBookLandingPage: {
      nodes: Array<
        BasicPage & {
          hero: {
            destinationUrlOnSubmit: string;
            onSubmitKeapTagIds: Array<string>;
          };
        }
      >;
    };
    sanityGetFileSettings: {
      downloadableFiles: Array<{
        fileParameterSlug: {
          current: string;
        };
        fileUrl: string;
      }>;
    };
    allSanityLpViralPlansPage: {
      nodes: Array<
        BasicPage & {
          sections: Array<{
            content: Array<Module>;
          }>;
        }
      >;
    };
    allSanityLpVslPage: {
      nodes: Array<{
        internalName: string;
        slug: {
          current: string;
        };
        sections: Array<{
          content: Array<Module>;
        }>;
      }>;
    };
    allSanityHpMarketingBrochure: {
      nodes: Array<
        BasicPage & {
          ctaSection: {
            withTypeform?: boolean;
            typeformId?: string;
            url?: string;
          };
        }
      >;
    };
    allSanityGuideWorkshop: {
      nodes: Array<
        BasicPage & {
          ctaSection: {
            formType?: 'calendar' | 'typeform' | 'none';
            url: string;
            typeformId?: string;
          };
        }
      >;
    };
    allSanityWelcomePage: {
      nodes: Array<BasicPage>;
    };
    allSanityHpConsultingPackage: {
      nodes: Array<BasicPage>;
    };
    allSanityLpLeadGen: {
      nodes: Array<BasicPage>;
    };
    sanityWelcomePageCommonFields: {
      supportSection: {
        supportTeamMembers: Array<{
          email: string;
        }>;
      };
    };
  };
}

const FlowPage = ({ data }: QueryData): React.ReactElement => {
  const [active, setActive] = useState<string | null>(null);

  const { nodes: plansPages } = data.allSanityLpViralPlansPage;
  const { nodes: vslPages } = data.allSanityLpVslPage;
  const {
    allSanityHpMarketingBrochure,
    allSanityGuideWorkshop,
    allSanityGtgvBookLandingPage,
    allSanityWelcomePage,
    allSanityHpConsultingPackage,
    allSanityLpLeadGen,
    sanityGetFileSettings,
    sanityWelcomePageCommonFields,
  } = data;

  function getCheckoutUrl(overridablePlan: OverridablePlan): string {
    return overridablePlan.plan
      ? CHECKOUT_URL +
          '/?add-to-cart=' +
          overridablePlan.plan.planId +
          (overridablePlan.coupon ? '&coupon_code=' + overridablePlan.coupon : '')
      : '';
  }

  function getPlanInfo(overridablePlan: OverridablePlan): React.ReactElement {
    return (
      <>
        <li className={styles.planName}>
          <strong>Plan name:</strong>
          {overridablePlan.name || overridablePlan.plan.name}
        </li>
        <ul className={styles.secondIndent}>
          <li>
            Keap Tag Id:
            {overridablePlan.keapTagId || overridablePlan.plan.keapTagId}
          </li>
          <li>Price: {overridablePlan.plan ? overridablePlan.plan.price : ''}</li>
          <li>Price with discount: {overridablePlan.priceWithDiscount || ''}</li>
          <li>Coupon: {overridablePlan.coupon || ''}</li>
          <li>
            Plan type:
            {overridablePlan.plan ? overridablePlan.plan.planType : ''}
          </li>
          <li>
            Woocommerce Id:
            {overridablePlan.plan ? overridablePlan.plan.planId : ''}
          </li>
          {overridablePlan.plan && (
            <li>
              Checkout url:
              <a
                href={getCheckoutUrl(overridablePlan)}
                onClick={() => setActive(getCheckoutUrl(overridablePlan))}
              >
                {' ' + getCheckoutUrl(overridablePlan)}
              </a>
            </li>
          )}
        </ul>
      </>
    );
  }

  const goViralUrl = 'goviral.hookpoint.com';
  const brochureUrl = 'intro.hookpoint.com';
  const welcomePagesUrl = 'welcome.hookpoint.com';
  const consultingPackageUrl = 'proposal.hookpoint.com';
  const connectUrl = 'connect.hookpoint.com';

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <PageSEO defaultTitle="Internal Flows" />
      <div className={styles.container}>
        <div className={styles.groupContainer}>
          <h2>{brochureUrl}</h2>
          <div className={styles.groupWrapper}>
            <span className={styles.groupTitle}>Marketing Brochure</span>
            {allSanityHpMarketingBrochure.nodes.map((brochure, i) => (
              <div className={styles.pageInfoContainer} key={i}>
                <p>
                  <strong>Title:</strong> {brochure.title}
                </p>
                <p>
                  <strong>Url: </strong>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://${brochureUrl}/${brochure.slug.current}`}
                  >
                    {`https://${brochureUrl}/${brochure.slug.current}`}
                  </a>
                </p>
                <p>
                  <strong>CTA destination: </strong>
                  {brochure.ctaSection.withTypeform ? (
                    `Typeform - ID: ${brochure.ctaSection.typeformId}`
                  ) : (
                    <a href={brochure.ctaSection.url}>{brochure.ctaSection.url}</a>
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.groupContainer}>
          <h2>{consultingPackageUrl}</h2>
          <div className={styles.groupWrapper}>
            <div className={styles.groupTitle}>
              Consulting Package Proposal
              <p>
                <strong>CTA:</strong> View draft Service Agreement
              </p>
            </div>
            {allSanityHpConsultingPackage.nodes.map((proposal, i) => (
              <div className={styles.pageInfoContainer} key={i}>
                <p>
                  <strong>Title:</strong> {proposal.title}
                </p>
                <p>
                  <strong>Url: </strong>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://${consultingPackageUrl}/${proposal.slug.current}`}
                  >
                    {`https://${consultingPackageUrl}/${proposal.slug.current}`}
                  </a>
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.groupContainer}>
          <h2>{connectUrl}</h2>
          <div className={styles.groupWrapper}>
            <span className={styles.groupTitle}>
              Lead Gen
              <p>
                <strong>CTA:</strong> Form that triggers emails to José, Paulo and Brendan
              </p>
            </span>
            {allSanityLpLeadGen.nodes.map((leadGen, i) => (
              <div className={styles.pageInfoContainer} key={i}>
                <p>
                  <strong>Title:</strong> {leadGen.title}
                </p>
                <p>
                  <strong>Url: </strong>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://${connectUrl}/${leadGen.slug.current}`}
                  >
                    {`https://${connectUrl}/${leadGen.slug.current}`}
                  </a>
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.groupContainer}>
          <h2>{welcomePagesUrl}</h2>
          <div className={styles.groupWrapper}>
            <div className={styles.groupTitle}>
              Welcome Page
              <p>
                <strong>CTA:</strong> Contact{' '}
                {sanityWelcomePageCommonFields.supportSection.supportTeamMembers
                  .map(teamMember => (teamMember.email ? teamMember.email : ''))
                  .filter(Boolean)
                  .join(', ')}
              </p>
            </div>
            {allSanityWelcomePage.nodes.map((welcomePage, i) => (
              <div className={styles.pageInfoContainer} key={i}>
                <p>
                  <strong>Title:</strong> {welcomePage.title}
                </p>
                <p>
                  <strong>Url: </strong>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://${welcomePagesUrl}/${welcomePage.slug.current}`}
                  >
                    {`https://${welcomePagesUrl}/${welcomePage.slug.current}`}
                  </a>
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.groupContainer}>
          <h2>{goViralUrl}</h2>
          <div className={styles.groupWrapper}>
            <span className={styles.groupTitle}>Get File</span>
            {allSanityGtgvBookLandingPage.nodes.map((gtgvPage, i) => (
              <div className={styles.pageInfoContainer} key={i}>
                <p>
                  <strong>Slug: </strong>
                  <a target="_blank" rel="noreferrer" href={urlJoin(gtgvPage.slug.current)}>
                    {urlJoin(gtgvPage.slug.current)}
                  </a>
                </p>
                <p>
                  <strong>Destination url: </strong>
                  <AnchorLink
                    href={
                      '#' +
                      urlJoin(
                        gtgvPage.hero.destinationUrlOnSubmit.split(/(^.*)?\?/)[1] ||
                          gtgvPage.hero.destinationUrlOnSubmit,
                      )
                    }
                    onClick={() =>
                      setActive(
                        urlJoin(
                          gtgvPage.hero.destinationUrlOnSubmit.split(/(^.*)?\?/)[1] ||
                            gtgvPage.hero.destinationUrlOnSubmit,
                        ),
                      )
                    }
                  >
                    {urlJoin(gtgvPage.hero.destinationUrlOnSubmit)}
                  </AnchorLink>
                </p>
                <p>
                  <strong>Keap Tag Id: </strong>
                  {gtgvPage.hero.onSubmitKeapTagIds}
                </p>
              </div>
            ))}
          </div>
          <div className={styles.groupWrapper}>
            <span className={styles.groupTitle}>Get File - New version</span>
          </div>
          <div className={styles.groupWrapper}>
            <span className={styles.groupTitle}>Guide Workshop</span>
            {allSanityGuideWorkshop.nodes.map((guideWorkshop, i) => (
              <div
                className={clsx(
                  styles.pageInfoContainer,
                  active === urlJoin(guideWorkshop.slug.current) && styles.active,
                )}
                id={urlJoin(guideWorkshop.slug.current)}
                key={i}
              >
                <p>
                  <strong>title:</strong>
                  {guideWorkshop.title}
                </p>
                <p>
                  <strong>Slug: </strong>
                  <a target="_blank" rel="noreferrer" href={urlJoin(guideWorkshop.slug.current)}>
                    {urlJoin(guideWorkshop.slug.current)}
                  </a>
                </p>
                <p>
                  <strong>CTA: </strong>
                  {guideWorkshop.ctaSection.formType === 'calendar'
                    ? `Calendar ${DEFAULT_SCHEDULE_ONCE_CALENDAR} (if not changed through ?socalendar)`
                    : ''}
                  {guideWorkshop.ctaSection.formType === 'typeform'
                    ? `Typeform - ID: ${guideWorkshop.ctaSection.typeformId}`
                    : ''}
                  {guideWorkshop.ctaSection.formType === 'none' ? (
                    <a href={guideWorkshop.ctaSection.url}>{guideWorkshop.ctaSection.url}</a>
                  ) : (
                    ''
                  )}
                </p>
              </div>
            ))}
          </div>
          <div className={styles.groupWrapper}>
            <span className={styles.groupTitle}>Plans Pages</span>
            {plansPages.map((planPage, i) => (
              <div
                className={clsx(
                  styles.pageInfoContainer,
                  active === urlJoin(planPage.slug.current) && styles.active,
                )}
                key={i}
                id={urlJoin(planPage.slug.current)}
              >
                <p className={styles.pageTitle}>
                  <strong>{planPage.title}</strong>{' '}
                </p>
                <p>
                  <strong>Slug:</strong> {planPage.slug.current}
                </p>
                {planPage.sections.map(section =>
                  section.content
                    .filter(module => !module.disabled)
                    .map(module => {
                      if (module.__typename === 'SanityLpViralPlansModule') {
                        return (
                          <div>
                            <p>
                              <strong>Plans Module:</strong>
                            </p>
                            {module.plans.map((overridablePlan, i) => (
                              <ul key={i}>{getPlanInfo(overridablePlan)}</ul>
                            ))}
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    }),
                )}
              </div>
            ))}
          </div>
          <div className={clsx(styles.groupWrapper)}>
            <span className={styles.groupTitle}>VSL Pages</span>
            {vslPages.map((vslPage, i) => (
              <div className={styles.pageInfoContainer} key={i}>
                <p className={styles.pageTitle}>
                  <strong>{vslPage.internalName}</strong>
                </p>
                <p>
                  <strong>Slug:</strong> {vslPage.slug.current}
                </p>
                {vslPage.sections.map(section =>
                  section.content
                    .filter(module => !module.disabled)
                    .map(module => {
                      if (module.__typename === 'SanityLpViralPlansModule') {
                        return (
                          <div>
                            <p>
                              <strong>Plans Module:</strong>
                            </p>
                            {uniq(module.plans).map((overridablePlan, i) => (
                              <ul key={i}>{getPlanInfo(overridablePlan)}</ul>
                            ))}
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    }),
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div style={{ marginTop: 20, padding: 20 }}>
        <h2 style={{ marginBottom: 20 }}>File urls per file url parameter from CMS</h2>
        {sanityGetFileSettings.downloadableFiles.map((file, i) => (
          <p key={i}>
            <strong>{file.fileParameterSlug.current}:</strong>{' '}
            <a href={file.fileUrl}>{file.fileUrl}</a>
          </p>
        ))}
      </div>
      <div style={{ marginTop: 20, padding: 20 }}>
        <h2 style={{ marginBottom: 20 }}>
          File urls per file url parameter (used only on old GoViral pages)
        </h2>
        {Object.entries(FILE_DOWNLOAD_URLS_BY_PARAM_VALUE).map(([urlParam, fileUrl], i) => (
          <p key={i}>
            <strong>{urlParam}:</strong> <a href={fileUrl}>{fileUrl}</a>
          </p>
        ))}
      </div>

      <div style={{ marginTop: 20, padding: 20 }}>
        <h2 style={{ marginBottom: 20 }}>Redirects file</h2>
        {replaceNewLinesWithBr(REDIRECTS_FILE_CONTENT)}
      </div>
    </>
  );
};

export default FlowPage;
