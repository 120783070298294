export default `
# global pages
/privacy-policy /privacy-policy 200
https://goviral.hookpoint.com/schedule-call https://hookpoint.com/schedule-call/ 301!
https://goviral.hookpoint.com/call-booked https://hookpoint.com/call-booked/ 301!

# global pages
/download-guide https://hookpoint.com/lets-talk/ 301!

# goviral.hookpoint.com
https://www.goviral.hookpoint.com/* https://goviral.hookpoint.com/:splat 301!
https://goviral.hookpoint.com/get-guide/get-guide /get-guide 301
/get-guide/*  https://gtgv-book-ygxs74.netlify.app/get-guide/:splat 200!
/hookpoint-ebook/*  https://gtgv-book-ygxs74.netlify.app/get-guide/hookpoint-ebook/:splat 200!
/omf-ebook/*  https://gtgv-book-ygxs74.netlify.app/get-guide/omf-ebook/:splat 200!
/the-guide-to-going-viral-masterclass/*  https://gtgv-book-ygxs74.netlify.app/get-guide/the-guide-to-going-viral-masterclass/:splat 200!
https://goviral.hookpoint.com/download-guide-to-going-viral /?banner=guide-downloading&file=guide-to-going-viral 301!
https://goviral.hookpoint.com/ / 200
https://goviral.hookpoint.com/special-deal /special-deal 200

https://goviral.hookpoint.com/plan-basic-monthly / 301! # old plan page
https://goviral.hookpoint.com/plan-pro-monthly / 301! # old plan page
https://goviral.hookpoint.com/v2 / 301! # old experiment page

# goingviral.hookpoint.com
https://www.goingviral.hookpoint.com/* https://goingviral.hookpoint.com/:splat 301!
https://goingviral.hookpoint.com/guide-to-going-viral /guide-to-going-viral 200
https://goingviral.hookpoint.com/ /join-today 301!
https://goingviral.hookpoint.com/join-today /join-today 200
https://goingviral.hookpoint.com/download-guide-to-going-viral /join-today?banner=guide-downloading&file=oriflame-guide 301!
https://goingviral.hookpoint.com/plan-basic-monthly-oriflame /plan-basic-monthly-oriflame 200

# goingviral.pro
https://www.goingviral.pro/* https://goingviral.pro/ 301!
https://goingviral.pro/ /get-guide 301!
https://goingviral.pro/get-guide /get-guide 200

https://goingviral.pro/hookpoint-guide /get-guide 301! # hookpoint-guide was deleted, added redirect just in case
https://goingviral.pro/plan-pro-monthly / 301! # old plan page

# guidetogoingviral.com
https://www.guidetogoingviral.com/* https://guidetogoingviral.com/:splat 301!
https://guidetogoingviral.com/ https://goviral.hookpoint.com/get-guide 301!

# free.hookpoint.com
https://free.hookpoint.com/* https://goviral.hookpoint.com/hookpoint-ebook/ 301!

# viraltrends.vip
https://www.viraltrends.vip/* https://viraltrends.vip/:splat 301!
https://viraltrends.vip/* https://goviral.hookpoint.com/?utm_source=webinar&utm_medium=live 301!

# viraltrends.today
https://www.viraltrends.today/* https://viraltrends.today/:splat 301!
https://viraltrends.today/ https://goviral.hookpoint.com/colin-samir 301!

# viraltrends.site
https://www.viraltrends.site/* https://viraltrends.site/:splat 301!
https://viraltrends.site/ https://goviral.hookpoint.com/higheredsocial 301!

# creator.hookpoint.com
https://creator.hookpoint.com/ /creators-contest 301!

# guide.hookpoint.com
https://guide.hookpoint.com/* https://goviral.hookpoint.com/get-guide/?utm_source=Podcast&utm_medium=Guide&utm_campaign=TheWolfofWallStreet 301!

# ivy.hookpoint.com
https://ivy.hookpoint.com/* https://goviral.hookpoint.com/get-guide/?utm_source=SpeakingEngagement&utm_medium=Presentation&utm_campaign=Ivy 301!

# https://hookpoint-landing-page-viral-site-ygxs74tv.netlify.app/
https://hookpoint-landing-page-viral-site-ygxs74tv.netlify.app/* https://hookpoint-landing-page-viral-site-ygxs74tv.netlify.app/:splat 200



# 404s
/get-guide /404 404!
/guide-to-going-viral /404 404!

/download-guide-to-going-viral /404 404!

/join-today /404 404!

/special-deal /404 404!
/the-futur /404 404!

/plan-basic-monthly /404 404!
/plan-basic-monthly-trial /404 404!
/plan-basic-monthly-oriflame /404 404!
/plan-pro-monthly /404 404!
/plan-pro-monthly-trial /404 404!


# old urls
/plans /plan-basic-monthly 301!
/join-now /plan-basic-monthly 301!
/plan /plan-basic-monthly-oriflame 301!
/plan-pro /plan-pro-monthly 301!
`;
